<template>
  <div class="regressionWap">
    <div v-if="!isApp" class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" width="95" height="23" />
      <div class="tabbar-right">
        <div class="tabbar-right-item">
          <img @click="toDownload" src="/subject/regression/wap/download.png" width="20" height="26" alt="" />
          <p>APP下载</p>
        </div>
        <div class="tabbar-right-item">
          <img @click="openLive" src="/subject/regression/server.png" alt="" width="26" height="26" />
          <p>联系客服</p>
        </div>
      </div>
    </div>
    <div class="regression_header">
      <img class="regression_header-banner" src="/subject/regression/wap/banner.png" alt="">
    </div>
    <div class="regression_body">
      <div class="regression_body-login" v-if="!isSign && (!isApp || !isLogin)">
        <div class="login-box">
          <input
            v-model="form.phone"
            ref="inputElement"
            type="text"
            placeholder="请输入手机号"
          />
          <div class="codeBox">
            <input v-model="form.code" type="text" placeholder="请输入验证码" />
            <span v-if="verControl" @click="verCode(form.phone)">获取验证码</span>
            <span v-else>重新获取({{ verSecond }})</span>
          </div>
          <img
            class="loginBtn"
            @click="submit(form)"
            src="/subject/regression/login-btn.png"
            alt=""
          />
        </div>
        
      </div>
      <div class="regression_body-sign" v-if="isApp && isLogin && !isSign">
        <img
          @click="activitySign()"
          src="/subject/regression/login-btn.png"
          alt=""
        />
      </div>
      <div class="regression_body-trade" v-if="isSign">
        <p>
          <span>$199</span>回归礼已打入账户
          <br/>
          请您查收
        </p>
        <img
          class="tradeBtn"
          @click="toTrade"
          src="/subject/regression/trade-two-btn.png"
          alt=""
        />
      </div>
      <div class="active-info" v-if="isSign">
        <div class="active-info-title">
          <span>我的参与进度：</span>
          <span>
            <img src="/subject/regression/user-icon.png" alt="">
            交易账户：{{ activityInfo.account }}
          </span>
        </div>
        <div class="active-info-box">
          <div>
            <p class="active-info-box-title">
              1、回归赠礼：
              <span v-if="isStepOneHave">
                进行中，【<span class="active-info-time">{{ stepOneTime }}</span>】后结束
              </span>
              <span v-else>
                已结束
              </span>
            </p>
            <div class="active-info-box-left">
              <div class="active-info-box-item">
                <img src="/subject/regression/wap/active1.png" alt="">
                <span class="active-status" v-if="activityInfo.sendStatus !== 3">已领取</span>
                <span class="active-status active-status-filed" v-else>已扣回</span>
              </div>
              <div class="active-info-box-item">
                <img src="/subject/regression/wap/active2.png" alt="">
                <div class="active-target">
                  <p>目标手数：<span>3</span></p>
                  <p>我的手数：<span>{{ activityInfo.userJyLots || '-- '}}</span></p>
                </div>
              </div>
            </div>
          </div>
          <img class="down-icon" src="/subject/regression/wap/down-icon.png" alt="">
          <div class="active-info-box-right">
            <p class="active-info-box-title">
              2、额外惊喜：
              <span v-if="!isStepOneOver">
                完成回归赠礼开启
              </span>
              <span v-else-if="isStepTwoHave">
                进行中，【<span class="active-info-time">{{ stepTwoTime }}</span>】后结束
              </span>
              <span v-else>
                已结束
              </span>
            </p>
            <div class="active-info-box-item">
              <img src="/subject/regression/wap/active3.png" alt="">
              <div class="active-target">
                <p>目标手数：<span>10</span></p>
                <p>我的手数：<span>{{ activityInfo.userYxLots || '-- '}}</span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="active-info-btn">
          <img src="/subject/regression/customer-btn.png" alt="" @click="openLive">
          <img src="/subject/regression/trade-btn.png" alt="" @click="toTrade">
        </div>
      </div>

      <img class="dividing-line" src="/subject/regression/wap/line.png" alt="">

      <div class="regression_role">
        <img class="regression_role-bg" src="/subject/regression/wap/role.png" alt="">
        <p class="regression_role-title">活动时间：</p>
        <p class="regression_role-content">【回归赠礼】 活动时长为7天， 【额外惊喜】 活动时长为30天。</p>
        <p class="regression_role-title">活动规则：</p>
        <p class="regression_role-content">
          1.【199美元现金】为平台为用户准备的 “回归赠礼” ， “回归赠礼” 有效期从领取当天起算（同时视为活动开始），7天后过期未使用系统将自动退回（7天后同时视为活动结束）。<br/>
          2.用户活动期间累计交易满3手可领$8现金红包，现金红包将在活动结束后统一派发。<br/>
          3.活动期间入金可获得与充值金额等额的美元赠金，即充多少送多少，赠金有效期为90天，最高可领10W美元赠金。<br/>
          4.活动结束后的30天内交易累计满10手额外再送$30美元现金。<br/>
          5.【199美元现金】只可直接用于交易，活动结束后扣回。活动期间产生的盈利归客户所有，盈利可提现可交易。<br/>
          6.如遇到节假日或特殊情况，奖励发放将顺延1-5日，敬请谅解。<br/>
          7.若用户中途退出活动，联系客服处理，届时活动奖励无法领取，额外惊喜活动也无法参与。<br/>
        </p>
        <p class="regression_role-title">注意事项：</p>
        <p class="regression_role-content">
          1.本平台开立真实交易账户。<br/>
          2.交易手数计算：开仓一手平仓一手，如此来回算一手，且开平仓需在活动期内。<br/>
          3.凡通过任何作弊手段或通过漏洞产生收益的，将取消参资格与奖励。<br/>
          4.本平台保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。<br/>
        </p>
      </div>
      <div class="regression_btn">
        <img src="/subject/regression/customer-btn.png" alt="" @click="openLive">
        <img v-if="!isSign" src="/subject/regression/receive-btn.png" alt="" @click="toReceive">
        <img v-else src="/subject/regression/trade-btn.png" alt="" @click="toTrade">
      </div>
      <div class="regression_download" ref="targetElement" v-if="!isApp">
        <div class="regression_download-title">下载APP，获取更多福利资讯</div>
        <div class="regression_download-box">
          <div class="regression_download-box-code">
            <a :href="iosMt4DownloadUrl">
              <img src="/subject/regression/wap/down-ios.png" alt="">
            </a>
            <a :href="androidMt4DownloadUrl">
              <img src="/subject/regression/wap/down-android.png" alt="">
            </a>
          </div>
          <div class="regression_download-box-code">
            <a :href="iosMt5DownloadUrl">
              <img src="/subject/regression/wap/down-ios.png" alt="">
            </a>
            <a :href="androidDownloadUrl">
              <img src="/subject/regression/wap/down-android.png" alt="">
            </a>
          </div>
        </div>
        <p class="regression_download-tips">
          1、MT4版本用MT4账户，MT5版本用MT5账户，两者不互通；<br/>
          2、如无法确定所用账户的MT版本，请联系 <span @click="openLive">在线客服</span> 查询。<br/>
        </p>
      </div>
      <div v-else class="regression_download"></div>
    </div>

    <div v-if="controlImgCodeDialog" class="codeDialog">
      <img :src="imgcodelocal" alt="" />
      <p>
        <span>图形验证码:</span>
        <input type="text" v-model="form.codeNumber" />
      </p>
      <div>
        <button
          @click="
            () => {
              this.controlImgCodeDialog = false;
            }
          "
        >
          取消
        </button>
        <button @click="confirm(form.codeNumber)">确定</button>
      </div>
    </div>

    <div v-if="signResultDialog" class="signResultDialog">
      <div class="close-btn">
        <img class="hover" src="/subject/regression/wap/close.png" alt="" @click="signResultDialog = false" width="16px" />
      </div>
      <div v-if="isSign">
        <img src="/subject/regression/wap/success.png" alt="" width="55px" />
        <p>领取成功!</p>
        <img class="hover" src="/subject/regression/wap/trade-two-btn.png" alt="" width="80%" @click="toTrade"/>
      </div>
      <div v-else>
        <img src="/subject/regression/wap/tips.png" alt="" width="55px" />
        <p>不符合参与条件</p>
        <img class="hover" src="/subject/regression/wap/website-btn.png" width="80%" alt="" @click="toWebsite"/>
      </div>
      
    </div>

    <div class="dark" v-if="controlImgCodeDialog || signResultDialog" @click="showDialog = false"></div>

    <contactusVue v-if="!isApp"></contactusVue>

  </div>
</template>

<script>
import ContactusVue from '../../Contactus.vue';
import { mapState, mapActions } from "vuex";
import { 
  newVerifyImgCode, 
  postLogin, 
  newQuestionImg, 
  appCode,
  postSignActivityhg,
  queryHgActivityUserInfo,
 } from "../../../../api/info";
import { getGuid } from "../../../../utils/guid";
import { encryptCBC } from "../../../../utils/IMEI";
import { setCookie, getCookie, deleteCookie } from "../../../../utils/cookies";

export default {
  name: 'regressionPc',
  components: {
    ContactusVue,
  },
  data() {
    return {
      screenWidth: null,
      navBarFixed: false,
      form: {
        phone: "",
        code: "",
        codeNumber: "",
      },
      imgcodelocal: "",
      timer: null,// 验证码读秒数
      // 控制显示验证码或读秒
      verControl: true,
      verSecond: 60,
      // 控制图片验证码弹窗
      controlImgCodeDialog: false,
      // 报名结果弹窗
      signResultDialog: false,
      // 是否已登录
      isLogin: false,
      // 是否已报名
      isSign: false,
      // 是否appt跳转
      isApp: false,
      activityInfo: {},
      isStepOneHave: false,
      isStepOneOver: false,
      stepOneTime: '',
      isStepTwoHave: false,
      stepTwoTime: '',
    };
  },
  watch: {
    screenWidth: function(n) {
      if (n > 900) {
        this.$router.push({
          name: 'regressionPc',
          query: this.$route.query || {}
        });
      }
    },
  },
  computed: {
    ...mapState('common', {
      androidMt4DownloadUrl: 'androidMt4DownloadUrl',
      androidDownloadUrl: 'androidDownloadUrl',
      iosMt4DownloadUrl: 'iosMt4DownloadUrl',
      iosMt5DownloadUrl: 'iosMt5DownloadUrl',
    })
  },
  created() {
    this.$emit('controlShow', true);
    // 判断是从A前还是A后跳转过来
    if (!sessionStorage.getItem('craig')) {
      if (!this.$route.query.craig) {
        sessionStorage.setItem('craig', window.location.origin);
      } else {
        sessionStorage.setItem('craig', this.$route.query.craig);
      }
    }
    // 判断是否从app端跳转
    const queryData = this.$route.query;
    const { accessKey = '', from = '' } = queryData;
    if(from === 'app_pm') {
      this.isApp = true;
    }
    if(accessKey) {
      this.isLogin = true;
      setCookie('token', accessKey)
    }
    if(from === 'app_pm' && !accessKey) {
      deleteCookie('token');
    }
    const token = getCookie('token');
    if(token) {
      this.getActivityUserInfo();
    }
  },
  mounted() {
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.addEventListener('scroll', this.handleScroll);
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  methods: {
    ...mapActions('common', {
      getTradeWapLink: 'getTradeWapLink',
    }),
    // 返回上级页面
    goback() {
      window.location.href = sessionStorage.getItem('craig');
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 90) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    openLive() {
      window.open(
        this.$parent.live800UrlUc
      );
    },
    toReceive() {
      const token = getCookie('token');
      if(token) {
        this.activitySign();
      } else {
        this.toTop();
      }
    },
    // 回到顶部
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    // 下载
    toDownload() {
      const element = this.$refs['targetElement'];
      if (element) {
          element.scrollIntoView({ behavior: 'auto' });
      }
    },
    // 去交易
    async toTrade() {
      // 在安卓app
      if(window.android) {
        window.android.gotoTrade();
        return
      }
      // 在ios
      if(window.webkit) {
        window.webkit.messageHandlers.gotoTrade.postMessage('1');
        return
      }
      // 在网页浏览器
      const h5LinkUrl = await this.getTradeWapLink();
      const sendData = {
        token: getCookie('token'),
        userStatus: 3,
      }
      window.open(h5LinkUrl + '?sendKey=' + encodeURIComponent(encryptCBC(JSON.stringify(sendData))));
    },
    // 去官网
    toWebsite() {
      const { origin } = location;
      window.open(origin)
    },
    // 提交验证码
    confirm(attr) {
      if (attr) {
        let data = {
          Mobile: this.form.phone,
          Token: this.guid,
          ImgCode: Number(attr),
          Appcode: appCode()['channel'] || appCode()['v'],
        };
        // 获取图片验证码接口
        newVerifyImgCode(data).then((res) => {
          if (res.IsSuccess) {
            this.$message({
              message: res.Data,
              type: "success",
            });
            this.verControl = !this.verControl;
            this.controlImgCodeDialog = false;
          } else {
            this.$message({
              message: res.Data,
              type: "warning",
            });
            this.controlImgCodeDialog = false;
          }
        });
      } else {
        this.$message({
          message: "请输入图片验证码结果!",
          type: "warning",
        });
      }
    },
    // 点击获取验证码
    verCode(attr) {
      if (this.isPhone(attr)) {
        this.controlImgCodeDialog = true;
        this.countdowm();
        this.guid = getGuid();
        newQuestionImg({Token: this.guid}).then(res => {
          const blob = new Blob([res])
          const url = window.URL.createObjectURL(blob);
          this.imgcodelocal = url;
        })
      } else {
        this.$message({
          message: "请输入正确手机号!",
          type: "warning",
        });
      }
    },
    // 正则判断电话号码格式是否正确
    isPhone(TEL) {
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (strTemp.test(TEL)) {
        return true;
      }
      return false;
    },
    // 读秒
    countdowm() {
      this.refreshData = window.setInterval(() => {
        this.verSecond--;
        if (this.verSecond < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.refreshData); //清除定时器
          this.verSecond = 60;
          this.verControl = true;
        }
      }, 1000);
    },
    // 提交手机号验证码
    submit(form) {
      if (form.phone && form.code) {
        const params = {
          isSmsLogin: 1,
          account: form.phone,
          password: encryptCBC(form.code),
          clientId: this.guid,
          fromType: 'pc',
        }
        postLogin(params).then((res) => {
          if (res.IsSuccess) {
            setCookie('token', res.Token)
            this.getActivityUserInfo('sign');
          } else {
            this.$message({
              message: res.Data,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          message: "请输入手机号和验证码！",
          type: "warning",
        });
      }
    },
    // 查询用户报名信息
    getActivityUserInfo(type) {
      queryHgActivityUserInfo().then(res => {
        if(res.IsSuccess) {
          this.isLogin = true;
          if(res.Data) {
            this.isSign = true;
            this.activityInfo = res.Data;
            const { cashCloseTime = 0, yxCloseTime = 0, delFlag } = this.activityInfo;
            if(delFlag === '1') {
              this.isStepOneHave = false;
              this.isStepOneOver = true;
              this.isStepTwoHave = false;
              return
            }
            const presentTime = Date.now();
            if(cashCloseTime && cashCloseTime > presentTime) {
              this.isStepOneHave = true;
              this.isStepOneOver = false;
              this.countdown(cashCloseTime, 1);
            } else {
              this.isStepOneHave = false;
              this.isStepOneOver = true;
            }
            if(yxCloseTime) {
              this.isStepTwoHave = true;
              if(yxCloseTime < presentTime) {
                this.isStepTwoHave = false;
              } else {
                this.countdown(yxCloseTime, 2);
              }
            } else {
              this.isStepTwoHave = false;
            }
          } else if(type === 'sign') {
            this.activitySign();
          }
        } else {
          if(res.Data === '登录已失效,请重新登录' || res.Data === '您的账号已在其他设备登录,本机已登出') {
            this.isLogin = false;
            deleteCookie('token');
          }
        }
      })
    },
    // 用户报名活动
    activitySign() {
      if(this.isSign) {
        return this.$message({
          message: '您已经报名，请不要重复报名',
          type: "warning",
        });
      }
      postSignActivityhg().then(res => {
        if (res.IsSuccess) {
          this.isSign = true;
          this.signResultDialog = true;
          this.getActivityUserInfo();
        } else {
          this.isSign = false;
          this.signResultDialog = true;
        }
      })
    },
    countdown(targetTimestamp, type) {
      // 获取当前时间戳
      const currentTimestamp = Date.now();

      // 计算时间差
      let timeRemaining = targetTimestamp - currentTimestamp;

      // 如果时间差小于等于0，表示目标时间已经过去
      if (timeRemaining <= 0) {
        if(type === 1) {
          this.isStepOneHave = false;
          this.isStepOneOver = true;
        } else {
          this.isStepTwoHave = false;
        }
        return;
      }

      // 每隔一秒更新一次时间差
      const intervalId = setInterval(() => {
        // 更新当前时间戳
        const currentTimestamp = Date.now();

        // 重新计算时间差
        timeRemaining = targetTimestamp - currentTimestamp;

        // 如果时间差小于等于0，清除定时器
        if (timeRemaining <= 0) {
          clearInterval(intervalId);
          if(type === 1) {
            this.isStepOneHave = false;
            this.isStepOneOver = true;
          } else {
            this.isStepTwoHave = false;
          }
          return;
        }
        // 格式化时间差并输出
        const formattedTime = this.formatTime(timeRemaining);
        if(type === 1) {
          this.stepOneTime = formattedTime;
        } else {
          this.stepTwoTime = formattedTime;
        }
      }, 1000);
    },
    formatTime(time) {
      // 将时间戳转换为天、小时、分钟和秒
      const days = Math.floor(time / (1000 * 60 * 60 * 24));
      const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((time % (1000 * 60)) / 1000);

      return `${days}天 ${hours}小时 ${minutes}分钟 ${seconds}秒`;
    },
  },
};
</script>

<style lang="less" scoped>

.regressionWap {
  background: #f8f8f8;
  .navBarFixed {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
  }
  .tabbar {
    background-color: #ffffff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    &-right {
      display: flex;
      align-items: center;
      &-item {
        text-align: center;
        color: #666;
        font-size: 12px;

        &:first-child {
          margin-right: 2rem;
        }
      }
    }
  }
  .regression_header {
    &-banner {
      width: 100%;
    }
  }

  .regression_body {
    background: url('/subject/regression/bg.png') no-repeat center;
    background-size: cover;
    margin-top: -4px;

    &-login {
      position: relative;
      width: 90%;
      margin: -40px auto 10px;
      background: url('/subject/regression/login-bg.png') no-repeat center;
      background-size: 100% 100%;
      z-index: 11;

      .login-box {
        width: 86%;
        padding: 5.6rem 0 2rem;
        margin: auto;
        input:focus {
          outline: none;
        }
        input {
          width: 100%;
          height: 3.6rem;
          background: #f4f4f4;
          border: 1px solid #f4f4f4;
          border-radius: 6px;
          font-size: 16px;
          text-indent: 15px;
          list-style: none;
          box-sizing: border-box;
          margin-bottom: 20px;
        }

        .codeBox {
          width: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          input {
            width: 100%;
          }

          span {
            width: 7rem;
            height: 2.6rem;
            line-height: 2.6rem;
            font-size: 16px;
            border-radius: 6px;
            background: #ffa540;
            text-align: center;
            color: #fff;
            position: absolute;
            top: 0.5rem;
            right: 8px;
            cursor: pointer;
          }
        }

        .loginBtn {
          width: 90%;
          display: block;
          cursor: pointer;
          position: relative;
          margin: auto;
        }
      }
    }

    &-trade {
      position: relative;
      width: 90%;
      margin: -40px auto 10px;
      background: url('/subject/regression/logied-bg.png') no-repeat center;
      background-size: 100% 100%;
      text-align: center;
      z-index: 11;
      padding: 45% 0 8%;
      p {
        margin-bottom: 6%;
        color: #ba5e1b;
        font-size: 20px;
        font-weight: 600;
        span {
          color: red;
        }
      }
      .tradeBtn {
        width: 76%;
        cursor: pointer;
      }
    }

    &-sign {
      text-align: center;
      margin: -40px 0 -15px;
      img {
        width: 75%;
      }
    }

    .active-info {
      width: 90%;
      margin: auto;
      background: #fff;
      border-radius: 12px;
      padding: 0.8rem 0;
      margin-bottom: 0.8rem;
      box-sizing: border-box;

      .active-info-time {
        color: red;
      }

      &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        font-weight: 600;
        margin-bottom: 1rem;
        >span {
          &:first-child {
            color: #ba5e1b;
            font-size: 16px;
          }
          &:last-child {
            display: flex;
            align-items: center;
            color: #666;
            font-size: 12px;

            img {
              width: 18px;
              margin-right: 4px;
            }
          }
        }
      }

      &-box {
        text-align: center;
        position: relative;
        &-title {
          margin: 0 0 1.2rem;
          background: #fffbea;
          color: #953a21;
          padding: 6px 0;
          font-weight: 600;
          white-space: nowrap;
          font-size: 0.75rem;
          border-radius: 50px;
        }
        &-left {
          padding: 0 4%;
          display: flex;
          justify-content: space-between;
          .active-info-box-item {
            position: relative;
            width: 48%;

            img {
              width: 100%;
            }

            .active-status {
              position: absolute;
              bottom: 25%;
              left: 15%;
              right: 15%;
              line-height: 32px;
              font-size: 14px;
              background: #ffc24c;
              color: #fff;
              border-radius: 4px;
            }
            .active-status-filed {
              background: #dddddd;
              color: #666;
            }
          }
        }
        &-right {
          .active-info-box-item {
            position: relative;
            width: 48%;
            margin: auto;
            img {
              width: 100%;
            }
          }
        }

        .active-target {
          position: absolute;
          bottom: 24%;
          left: 8%;
          right: 8%;
          font-size: 14px;
          color: #501700;
          span {
            color: red;
          }
        }

        .down-icon {
          width: 1.2rem;
          display: block;
          margin: 0.8rem auto 1rem;
        }
      }

      &-btn {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0.8rem auto 0.2rem;
        img {
          width: 48%;
        }
      }
    }

    .regression_role {
      width: 90%;
      margin: auto;
      &-bg {
        width: 100%;
      }
      &-title {
        color: #ae5f40;
        margin: 25px 0 5px;
        font-weight: 700;
        font-size: 16px;
      }
      &-content {
        color: #ae5f40;
        line-height: 26px;
        font-size: 16px;
      }
    }

    .regression_btn {
      display: flex;
      justify-content: space-between;
      width: 92%;
      margin: 1rem auto;
      img {
        width: 48%;
      }
    }

    .regression_download {
      width: 90%;
      margin: auto;
      padding-bottom: 40px;

      &-title {
        color: #a7431c;
        font-size: 1.4rem;
        font-weight: 600;
      }

      &-box {
        margin-top: 20px;
        &-code {
          padding: 80px 35px 20px;
          margin-bottom: 30px;
          &:first-child {
            background: url('/subject/regression/wap/download1-bg.png') no-repeat center;
            background-size: 100% 100%;
          }
          &:last-child {
            background: url('/subject/regression/wap/download2-bg.png') no-repeat center;
            background-size: 100% 100%;
          }
          a {
            width: 100%;
            margin-bottom: 15px;
            display: block;
            img {
              width: 100%;
            }
          }
        }
      }

      &-tips {
        color: #a7431c;
        font-size: 12px;
        line-height: 26px;
        span {
          color: red;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    .dividing-line {
      width: 55%;
      display: block;
      margin: 26px auto;
    }
  }

  .codeDialog {
    width: 80%;
    height: 200px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 15px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    img {
      width: 120px;
      margin-bottom: 0;
    }

    p {
      span {
        color: #5f0e15;
        font-size: 18px;
        font-weight: 600;
      }

      input {
        width: 150px;
        height: 30px;
        background-color: #f4f4f4;
        border: none;
        margin-left: 10px;
        text-indent: 10px;
        color: #5f0e15;
        font-size: 16px;
      }
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: 100px;
        height: 40px;
        border: 1px solid #f64b44;
        background-color: #ffffff;
        color: #f64b44;
        font-size: 18px;
        border-radius: 8px;
        margin: 0 10px;
        cursor: pointer;
      }

      button:nth-child(2) {
        background-color: #f64b44;
        color: #ffffff;
      }
    }
  }

  .signResultDialog {
    width: 80%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 12px;
    z-index: 99;
    text-align: center;
    padding: 20px 20px 30px;
    box-sizing: border-box;

    >div {
      p {
        color: #9e1100;
        margin: 0.6rem 0 1.5rem;
        font-size: 18px;
        font-weight: 600;
      }
    }
    
    .hover {
      cursor: pointer;
    }

    .close-btn {
      padding-top: 0;
      text-align: right;
    }
  }

  .dark {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 95;
  }
}

</style>
